<template>
    <div
        class="flex flex-col items-center content-center align-middle w-screen h-screen justify-center bg-white dark:bg-gray-900">
        <form>
            <div class="flex flex-col items-center m-auto p-14">
                <NotifyaLogo
                    classes="text-6xl text-gray-800 dark:text-white m-10" />
                <div
                    class="flex flex-col items-center align-middle"
                    v-if="!mfaOptions">
                    <input
                        type="email"
                        id="username"
                        class="p-2 text-gray-900 dark:text-white text-base form-cotrol block w-full px-4 font-normal bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 m-3 focus:outline-none"
                        placeholder="Email"
                        v-on:keyup.enter="() => login()"
                        v-model="username" />
                    <input
                        type="password"
                        id="password"
                        class="p-2 text-gray-900 dark:text-white text-base form-cotrol block w-full px-4 font-normal bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 m-3 focus:outline-none"
                        placeholder="Heslo"
                        v-on:keyup.enter="() => login()"
                        v-model="password" />
                    <ButtonsSubmit
                        title="Přihlásit se"
                        class="w-full m-3"
                        :loading="isLoading"
                        @click="() => login()" />
                    <!-- <span
                        v-if="!isLoading"
                        @click="LoginWithGoogle()"
                        class="p-1 text-base w-full text-center m-3 rounded border-2 border-emerald-600 text-gray-800 dark:text-white cursor-pointer flex flex-row align-middle items-center justify-center">
                        <font-awesome-icon
                            :icon="['fab', 'google']"
                            class="text-gray-800 dark:text-white text-xl mx-2 my-2" />
                        <span>Login with Google</span>
                    </span> -->
                    <span
                        v-if="!isLoading"
                        @click="passwordResetModal = true"
                        class="text-gray-400 mt-2 align-middle items-center justify-center cursor-pointer hover:text-gray-500">
                        Zapomenuté heslo?
                    </span>
                </div>
                <div class="flex flex-col items-center align-middle" v-else>
                    <span class="text-gray-800 dark:text-white text-2xl mb-2">
                        Dvoufázové ověření
                    </span>
                    <span class="w-72 text-center text-gray-400">
                        Pro přihlášení je potřeba dvoufázové ověření. Pokračujte
                        prosím podle instrukcí prohlížeče.
                    </span>
                    <Loading />
                    <span
                        @click="startMfaVerification()"
                        class="text-gray-400 mt-2 align-middle items-center justify-center cursor-pointer hover:text-gray-500">
                        Pokud se nic nestane, klikněte sem.
                    </span>
                    <span
                        @click="resetAuth()"
                        class="text-gray-400 mt-2 align-middle items-center justify-center cursor-pointer hover:text-gray-500">
                        Zrušit ověření
                    </span>
                </div>
            </div>
        </form>
    </div>
    <Modal
        :show="passwordResetModal"
        title="Zapomenuté heslo"
        class="min-w-fit"
        @closeModal="passwordResetModal = false">
        <div class="flex flex-col items-center min-w-fit">
            <span class="text-gray-500 text-lg my-1">
                Zadejte emailovou adresu, na kterou vám pošleme odkaz pro
                obnovení hesla.
            </span>
            <input
                type="email"
                id="username"
                class="p-2 text-gray-800 dark:text-white w-64 px-4 font-normal bg-clip-padding rounded transition ease-in bg-gray-300 dark:bg-gray-800 m-3 focus:outline-none"
                placeholder="Email"
                v-model="username" />
            <span
                @click="() => resetPassword()"
                class="p-3 text-xl text-center m-3 rounded bg-emerald-600 text-white cursor-pointer hover:bg-emerald-700">
                Odeslat
            </span>
        </div>
    </Modal>
</template>

<script lang="ts">
import NotifyaLogo from "~/components/NotifyaLogo.vue";
import Loading from "~/components/Loading.vue";
import { type User } from "~/models/user";
import { useBusinessesStore } from "~/composables/businesss";
import { startAuthentication } from "@simplewebauthn/browser";

definePageMeta({
    layout: "empty",
    name: "Přihlášení",
});

export default defineNuxtComponent({
    name: "Login",
    setup() {
        useSeoMeta({
            title: "Přihlášení | Notifya",
            description: "Přihlášení do aplikace Notifya",
        });
        const errorMessagesStore = useErrorMessagesStore();
        const username = ref<string>(""),
            password = ref<string>("");
        const isLoading = ref<boolean>(false);
        const passwordResetModal = ref<boolean>(false);
        const mfaOptions = ref();
        let mfaTimer = ref();

        return {
            errorMessagesStore,
            username,
            password,
            isLoading,
            passwordResetModal,
            mfaOptions,
            mfaTimer,
        };
    },
    methods: {
        async login(mfaResult: any = undefined) {
            this.isLoading = true;
            if (this.username == "" || this.password == "") {
                this.isLoading = false;
                this.$toast.info("Vyplňte všechna pole", {
                    timeout: 1000,
                });
                return;
            }
            if (this.mfaTimer != undefined) clearTimeout(this.mfaTimer);
            let response: any;
            try {
                response = await this.$apiPostRequest("/auth/login/", {
                    username: this.username,
                    password: this.password,
                    mfaResult: mfaResult,
                });
            } catch (error: any) {
                this.isLoading = false;
                this.mfaOptions = undefined;
                if (error.response?._data?.status == "error") {
                    const message =
                        await this.errorMessagesStore.getMessageByCode(
                            error.response._data.error
                        );
                    this.$toast.error(message ?? "Přihlášení se nezdařilo");
                } else {
                    this.$toast.error("Při přihlašování došlo k chybě");
                }
                return;
            }
            if (response != null && response.status == "mfa-required") {
                this.mfaOptions = (<any>response).options;
                this.isLoading = false;
                this.startMfaVerification();
                this.mfaTimer = setTimeout(() => {
                    this.mfaOptions = undefined;
                    this.isLoading = false;
                    this.$toast.error("Čas na ověření vypršel", {
                        timeout: 2000,
                    });
                }, 60000);
                return;
            }
            if (response != null && (<any>response).data.success === true) {
                let data = (<any>response).data;
                this.isLoading = true;
                const userStore = useUser();
                const business = useBusinessesStore();
                userStore.setUser({
                    email: data.user.email,
                    id: data.user.id,
                    name: data.user.name,
                    googleConnected: data.user.googleConnected,
                    phone: data.user.phone,
                    passwordChanged: data.user.passwordChanged,
                    passwordSet: data.user.passwordSet,
                    address: data.user.address,
                    analyticsOptOut: data.user.analyticsOptOut,
                } as User);
                await business.loadBusinesses();
                this.$toast.success("Vítejte", {
                    timeout: 1000,
                });
                const route = useRoute();
                if (business.getBusinesses.length == 0) {
                    this.$router.push("/onboarding/new-business");
                }
                if (route.query.redirect !== undefined) {
                    this.$router.push("/" + route.query.redirect);
                } else {
                    this.$router.push("/dash");
                }
            } else {
                this.isLoading = false;
                this.mfaOptions = undefined;
                this.$toast.error("Přihlášení se nezdařilo", {
                    timeout: 2000,
                });
            }
        },
        LoginWithGoogle() {
            this.isLoading = true;
            const apiEndpoint = useRuntimeConfig().public.API_ENDPOINT;
            navigateTo(apiEndpoint + "/auth/federated/google", {
                replace: true,
                external: true,
            });
        },
        async resetPassword() {
            this.passwordResetModal = false;
            this.isLoading = true;
            const response = await this.$apiGetRequest(
                "/auth/resetPassword/?email=" + this.username
            ).catch(async (error: any) => {
                this.isLoading = false;
                console.log(error.response._data.error);
                if (error.response._data.status == "error") {
                    this.password = "";
                    const message =
                        await this.errorMessagesStore.getMessageByCode(
                            error.response._data.error
                        );
                    this.$toast.error(message, {
                        timeout: 2000,
                    });
                } else {
                    this.$toast.error("Při resetování hesla došlo k chybě", {
                        timeout: 2000,
                    });
                }
                return;
            });
            if (response != null && (<any>response).status === "success") {
                this.isLoading = false;
                this.$toast.success(
                    "Odkaz na reset hesla byl odeslán na emailovou adresu"
                );
            }
        },
        async startMfaVerification() {
            const authResult = await startAuthentication(this.mfaOptions).catch(
                (error: any) => {
                    console.log(error);
                    return;
                }
            );
            if (authResult == undefined) {
                this.isLoading = false;
                this.$toast.error("Authentikace neproběhla.");
                return;
            }
            this.login(authResult);
        },
        resetAuth() {
            this.password = "";
            this.mfaOptions = undefined;
            if (this.mfaTimer != undefined) clearTimeout(this.mfaTimer);
            this.mfaTimer = undefined;
            this.isLoading = false;
        },
    },
    components: {
        NotifyaLogo,
        Loading,
    },
    async mounted() {
        try {
            const resp = await this.$apiGetRequest("/user");
            if (resp.status === "success") {
                this.$router.push("/dash");
            }
        } catch (e) {}
    },
});
</script>
