<template>
    <div class="h-full w-full flex flex-col">
        <div class="flex flex-col">
            <div
                class="bg-gray-300 dark:bg-gray-800 rounded-lg px-5 py-3 flex flex-row items-center justify-between">
                <HomeSectionHeader>Zabezpečení</HomeSectionHeader>
            </div>
        </div>
        <div
            v-if="loading"
            class="flex items-center w-full h-full align-middle justify-center">
            <Loading />
        </div>
        <div v-else class="flex flex-col">
            <h3 class="text-gray-800 dark:text-gray-400 text-2xl mt-5">
                Heslo
            </h3>
            <span class="text-gray-600 dark:text-gray-400 mt-2">
                Heslo změněno:
                {{
                    user.getUser?.passwordChanged
                        ? new Date(
                              user.getUser?.passwordChanged
                          ).toLocaleDateString("cs-CZ")
                        : "Nikdy"
                }}
            </span>
            <ButtonsGeneric
                title="Změnit heslo"
                icon="key"
                class="w-64 mt-2"
                @click="() => (passwordChangeModal = true)" />

            <h3 class="text-gray-800 dark:text-gray-400 text-2xl mt-10 mb-2">
                Dvoufázové ověření
            </h3>
            <div
                v-for="a in authenticators"
                class="flex flex-row bg-slate-200 dark:bg-gray-800 rounded-md p-2 items-center align-middle w-64">
                <font-awesome-icon
                    icon="key"
                    class="text-gray-800 dark:text-gray-100 mr-2" />
                <span class="text-gray-800 dark:text-gray-100">
                    {{ a.name }}
                </span>
                <font-awesome-icon
                    @click="() => removeMfa(a.id)"
                    icon="trash"
                    class="text-red-500 hover:text-red-400 cursor-pointer ml-auto" />
            </div>
            <ButtonsSubmit
                title="Přidat passkey"
                icon="lock"
                class="w-64 mt-2"
                @click="registerMfa" />
        </div>
    </div>
    <Modal
        title="Změna hesla"
        :show="passwordChangeModal"
        @closeModal="() => closePasswordModal()"
        class="min-w-fit">
        <div class="flex flex-col justify-center items-center w-full">
            <span class="text-gray-800 dark:text-gray-400 text-lg"
                >Zadejte nové heslo</span
            >
            <input
                v-model="changePass1"
                type="password"
                placeholder="Nové heslo"
                class="mt-4 w-64 dark:border rounded-lg p-2.5 bg-gray-300 dark:bg-gray-800 dark:border-gray-600 placeholder-gray-600 dark:placeholder-gray-400 text-gray-800 dark:text-white focus:ring-emerald-500 focus:border-emerald-500" />
            <input
                v-model="changePass2"
                type="password"
                placeholder="Potvrzení hesla"
                class="mt-2 w-64 dark:border rounded-lg p-2.5 bg-gray-300 dark:bg-gray-800 dark:border-gray-600 placeholder-gray-600 dark:placeholder-gray-400 text-gray-800 dark:text-white focus:ring-emerald-500 focus:border-emerald-500" />
            <ButtonsSubmit
                title="Změnit heslo"
                class="w-64 mt-4"
                @click="() => changePassword()"
                v-if="!changingPassword" />
            <font-awesome-icon
                v-else
                icon="circle-notch"
                class="text-emerald-500 text-3xl mt-4 animate-spin" />
        </div>
    </Modal>
</template>
<script lang="ts">
import { startRegistration } from "@simplewebauthn/browser";
import type { Authenticator } from "~/models/authenticator";
definePageMeta({
    layout: "settings",
});
export default defineNuxtComponent({
    setup() {
        useSeoMeta({
            title: "Zabezpečení | Notifya",
        });
        const loading = ref<boolean>(false);
        const error = ref(null);
        const user = useUser();
        const passwordChangeModal = ref(false);
        const changingPassword = ref(false);
        const authenticators = ref<Authenticator[]>([]);

        const changePass1 = ref("");
        const changePass2 = ref("");
        return {
            loading,
            error,
            user,
            passwordChangeModal,
            changingPassword,
            changePass1,
            changePass2,
            startRegistration,
            authenticators,
        };
    },
    methods: {
        async changePassword() {
            this.changingPassword = true;
            if (this.changePass1 !== this.changePass2) {
                this.$toast.error("Hesla se neshodují.");
                this.changingPassword = false;
                return;
            }

            if (this.changePass1.length < 6) {
                this.$toast.error("Heslo musí mít alespoň 6 znaků.");
                this.changingPassword = false;
                return;
            }

            const resp: any = await this.$apiPostRequest(
                "/user/changePassword",
                {
                    password: this.changePass1,
                }
            ).catch((e: any) => {
                this.$toast.error("Nepodařilo se změnit heslo");
                this.changingPassword = false;
                return;
            });
            if (resp != null && resp.status === "success") {
                this.$toast.success("Heslo bylo změněno");
                this.user.setUser({
                    ...this.user.getUser,
                    passwordChanged: new Date().toISOString(),
                });
            }
            this.closePasswordModal();
            this.changingPassword = false;
        },
        closePasswordModal() {
            this.changePass1 = "";
            this.changePass2 = "";
            this.passwordChangeModal = false;
        },
        async loadMfaAuthenticators() {
            const resp = await this.$apiGetRequest("/user/mfa").catch(
                (e: any) => {
                    this.$toast.error(
                        "Nepodařilo se získat nastavení dvoufázového ověření"
                    );
                    return;
                }
            );
            if (resp != null) {
                this.authenticators = resp.data;
            }
        },
        async removeMfa(id: string) {
            const resp = await this.$apiDeleteRequest(
                "/user/mfa?id=" + id
            ).catch((e: any) => {
                this.$toast.error("Nepodařilo se odebrat dvoufázové ověření");
                return;
            });
            if (resp != null) {
                this.$toast.success("Dvoufázové ověření odebráno");
                this.loadMfaAuthenticators();
            }
        },
        async registerMfa() {
            const resp = await this.$apiGetRequest(
                "/auth/webauthn/getOptions"
            ).catch((e: any) => {
                this.$toast.error(
                    "Nepodařilo se získat nastavení dvoufázového ověření"
                );
                return;
            });
            if (resp != null) {
                const credential = await this.startRegistration(resp);
                const resp2 = await this.$apiPostRequest(
                    "/auth/webauthn/register",
                    {
                        ...credential,
                    }
                ).catch((e: any) => {
                    this.$toast.error(
                        "Nepodařilo se zaregistrovat dvoufázové ověření"
                    );
                    return;
                });
                if (resp2 != null) {
                    this.loadMfaAuthenticators();
                    this.$toast.success("Dvoufázové ověření přidáno");
                }
            }
        },
    },
    mounted() {
        this.loadMfaAuthenticators();
    },
});
</script>
