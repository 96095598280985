<template>
    <div class="flex flex-col align-middle items-center justify-center h-full">
        <Steps
            :model="[
                {
                    label: 'Registrace',
                },
                {
                    label: 'Ověření emailu',
                },
                {
                    label: 'Vyvoření firemního profilu',
                },
                {
                    label: 'Výběr předplatného',
                },
                {
                    label: 'Hotovo',
                },
            ]"
            :activeStep="3"
            class="my-4" />
        <h1
            class="text-4xl text-gray-800 dark:text-white font-bold mt-6 mb-4 text-center">
            Vyberte si plán předplatného
        </h1>
        <span
            class="text-gray-600 dark:text-white text-base text-center mb-4 w-full">
            Vyberte si plán předplatného, který vám nejvíce vyhovuje. Můžete si
            vybrat z několika plánů, které se liší podle funkčnosti a ceny.
        </span>
        <div
            class="flex flex-col lg:flex-row flex-wrap w-full align-middle items-center justify-center mt-4">
            <div v-for="plan in plans" class="w-80">
                <PlanCard
                    :plan="plan"
                    :current="false"
                    @activate="() => activatePlan(plan.id)"
                    :loadingId="loadingId" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import type { SubscriptionPlan } from "~/models/plan";
import Steps from "primevue/steps";
definePageMeta({
    layout: "onboarding",
});
export default defineNuxtComponent({
    setup() {
        useSeoMeta({
            title: "Plán předplatného | Notifya",
            description:
                "Vyberte si plán předplatného, který vám nejvíce vyhovuje",
        });
        const plans = ref<SubscriptionPlan[]>([]);
        const loading = ref(true);
        const error = ref("");
        const loadingId = ref("");
        const businessesStore = useBusinessesStore();
        return {
            plans,
            loading,
            error,
            businessesStore,
            loadingId,
        };
    },
    components: {
        Steps,
    },
    mounted() {
        this.fetchPlans();
    },
    methods: {
        async fetchPlans() {
            const response = await this.$apiGetRequest("/system/plans").catch(
                (e: any) => {
                    console.log(e);
                    this.$toast.error(
                        "Nepodařilo se načíst plány předplatného"
                    );
                    this.loading = false;
                    this.error = "Nepodařilo se načíst plány předplatného";
                }
            );
            if (response.status == "success") {
                this.plans = response.data.plans;
            } else {
                this.error = response.message;
                this.$toast.error(response.message);
            }
            this.loading = false;
        },
        async activatePlan(id: string) {
            if (this.loading) return;
            this.loading = true;
            this.loadingId = id;
            const resp: any = await this.$apiPostRequest(
                "/billing/startSubscription",
                {
                    planId: id,
                    businessId: this.businessesStore.getCurrentBusinessId,
                }
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se aktivovat plán předplatného");
                return;
            });
            if (resp.status == "success") {
                navigateTo(resp.data.payment_link, {
                    external: true,
                });
            } else {
                this.$toast.error(resp.message);
            }
            this.loading = false;
            this.loadingId = "";
        },
    },
});
</script>
