
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as googleCallbackEhq23OBPK1Meta } from "/vercel/path1/pages/auth/googleCallback.vue?macro=true";
import { default as index90dSM5RQPpMeta } from "/vercel/path1/pages/auth/index.vue?macro=true";
import { default as loginf4f2DsNMxvMeta } from "/vercel/path1/pages/auth/login.vue?macro=true";
import { default as passwordResethlE2b4U7PLMeta } from "/vercel/path1/pages/auth/passwordReset.vue?macro=true";
import { default as calendaryco83vJuaZMeta } from "/vercel/path1/pages/calendar.vue?macro=true";
import { default as indexeaGwdTkXsLMeta } from "/vercel/path1/pages/customers/[id]/index.vue?macro=true";
import { default as indexwZqqNYl6t4Meta } from "/vercel/path1/pages/customers/index.vue?macro=true";
import { default as newnv1fIXnqZAMeta } from "/vercel/path1/pages/customers/new.vue?macro=true";
import { default as dashlnjF4rhskjMeta } from "/vercel/path1/pages/dash.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as finishfJzYGzUL2yMeta } from "/vercel/path1/pages/onboarding/finish.vue?macro=true";
import { default as new_45business8uNkbRCOfcMeta } from "/vercel/path1/pages/onboarding/new-business.vue?macro=true";
import { default as planixiY6gzCpKMeta } from "/vercel/path1/pages/onboarding/plan.vue?macro=true";
import { default as signupBvo2zwSzhlMeta } from "/vercel/path1/pages/onboarding/signup.vue?macro=true";
import { default as verify_45emailFX4WOBXcXyMeta } from "/vercel/path1/pages/onboarding/verify-email.vue?macro=true";
import { default as _91id_93siLu779tF9Meta } from "/vercel/path1/pages/orders/detail/[id].vue?macro=true";
import { default as indexv80KOeWQoOMeta } from "/vercel/path1/pages/orders/index.vue?macro=true";
import { default as price_45listbX8gLUAQ2EMeta } from "/vercel/path1/pages/price-list.vue?macro=true";
import { default as indexfQJXkxQnLIMeta } from "/vercel/path1/pages/settings/billing/index.vue?macro=true";
import { default as payment_cancelwD5yJgXR9VMeta } from "/vercel/path1/pages/settings/billing/payment_cancel.vue?macro=true";
import { default as successG3sn5pgkpdMeta } from "/vercel/path1/pages/settings/billing/success.vue?macro=true";
import { default as businessesdymwaS2sUmMeta } from "/vercel/path1/pages/settings/businesses.vue?macro=true";
import { default as external_45calendarDdOdCuneT9Meta } from "/vercel/path1/pages/settings/external-calendar.vue?macro=true";
import { default as indexRDl0kZWQMpMeta } from "/vercel/path1/pages/settings/index.vue?macro=true";
import { default as emailuOhHI2Hr0GMeta } from "/vercel/path1/pages/settings/modules/email.vue?macro=true";
import { default as indexI2CMmEWggvMeta } from "/vercel/path1/pages/settings/modules/index.vue?macro=true";
import { default as reservations15mcr5xgqiMeta } from "/vercel/path1/pages/settings/modules/reservations.vue?macro=true";
import { default as reservationTimes6PVfqFIcIMMeta } from "/vercel/path1/pages/settings/modules/reservationTimes.ts?macro=true";
import { default as sms43xJxsfjerMeta } from "/vercel/path1/pages/settings/modules/sms.vue?macro=true";
import { default as profile6lYHa2IqkMMeta } from "/vercel/path1/pages/settings/profile.vue?macro=true";
import { default as securitygobTi49Ib1Meta } from "/vercel/path1/pages/settings/security.vue?macro=true";
export default [
  {
    name: "auth-googleCallback",
    path: "/auth/googleCallback",
    meta: googleCallbackEhq23OBPK1Meta || {},
    component: () => import("/vercel/path1/pages/auth/googleCallback.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: index90dSM5RQPpMeta || {},
    redirect: "/auth/login",
    component: () => import("/vercel/path1/pages/auth/index.vue")
  },
  {
    name: "Přihlášení",
    path: "/auth/login",
    meta: loginf4f2DsNMxvMeta || {},
    component: () => import("/vercel/path1/pages/auth/login.vue")
  },
  {
    name: "auth-passwordReset",
    path: "/auth/passwordReset",
    meta: passwordResethlE2b4U7PLMeta || {},
    component: () => import("/vercel/path1/pages/auth/passwordReset.vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/vercel/path1/pages/calendar.vue")
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/vercel/path1/pages/customers/[id]/index.vue")
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/vercel/path1/pages/customers/index.vue")
  },
  {
    name: "customers-new",
    path: "/customers/new",
    component: () => import("/vercel/path1/pages/customers/new.vue")
  },
  {
    name: "dash",
    path: "/dash",
    component: () => import("/vercel/path1/pages/dash.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexzhmolsBfabMeta || {},
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "onboarding-finish",
    path: "/onboarding/finish",
    meta: finishfJzYGzUL2yMeta || {},
    component: () => import("/vercel/path1/pages/onboarding/finish.vue")
  },
  {
    name: "onboarding-new-business",
    path: "/onboarding/new-business",
    meta: new_45business8uNkbRCOfcMeta || {},
    component: () => import("/vercel/path1/pages/onboarding/new-business.vue")
  },
  {
    name: "onboarding-plan",
    path: "/onboarding/plan",
    meta: planixiY6gzCpKMeta || {},
    component: () => import("/vercel/path1/pages/onboarding/plan.vue")
  },
  {
    name: "onboarding-signup",
    path: "/onboarding/signup",
    meta: signupBvo2zwSzhlMeta || {},
    component: () => import("/vercel/path1/pages/onboarding/signup.vue")
  },
  {
    name: "onboarding-verify-email",
    path: "/onboarding/verify-email",
    meta: verify_45emailFX4WOBXcXyMeta || {},
    component: () => import("/vercel/path1/pages/onboarding/verify-email.vue")
  },
  {
    name: "orders-detail-id",
    path: "/orders/detail/:id()",
    component: () => import("/vercel/path1/pages/orders/detail/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path1/pages/orders/index.vue")
  },
  {
    name: "price-list",
    path: "/price-list",
    component: () => import("/vercel/path1/pages/price-list.vue")
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    meta: indexfQJXkxQnLIMeta || {},
    component: () => import("/vercel/path1/pages/settings/billing/index.vue")
  },
  {
    name: "settings-billing-payment_cancel",
    path: "/settings/billing/payment_cancel",
    component: () => import("/vercel/path1/pages/settings/billing/payment_cancel.vue")
  },
  {
    name: "settings-billing-success",
    path: "/settings/billing/success",
    component: () => import("/vercel/path1/pages/settings/billing/success.vue")
  },
  {
    name: "settings-businesses",
    path: "/settings/businesses",
    meta: businessesdymwaS2sUmMeta || {},
    component: () => import("/vercel/path1/pages/settings/businesses.vue")
  },
  {
    name: "settings-external-calendar",
    path: "/settings/external-calendar",
    meta: external_45calendarDdOdCuneT9Meta || {},
    component: () => import("/vercel/path1/pages/settings/external-calendar.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexRDl0kZWQMpMeta || {},
    redirect: "/settings/profile",
    component: () => import("/vercel/path1/pages/settings/index.vue")
  },
  {
    name: "settings-modules-email",
    path: "/settings/modules/email",
    meta: emailuOhHI2Hr0GMeta || {},
    component: () => import("/vercel/path1/pages/settings/modules/email.vue")
  },
  {
    name: "settings-modules",
    path: "/settings/modules",
    meta: indexI2CMmEWggvMeta || {},
    component: () => import("/vercel/path1/pages/settings/modules/index.vue")
  },
  {
    name: "settings-modules-reservations",
    path: "/settings/modules/reservations",
    meta: reservations15mcr5xgqiMeta || {},
    component: () => import("/vercel/path1/pages/settings/modules/reservations.vue")
  },
  {
    name: "settings-modules-reservationTimes",
    path: "/settings/modules/reservationTimes",
    component: () => import("/vercel/path1/pages/settings/modules/reservationTimes.ts")
  },
  {
    name: "settings-modules-sms",
    path: "/settings/modules/sms",
    meta: sms43xJxsfjerMeta || {},
    component: () => import("/vercel/path1/pages/settings/modules/sms.vue")
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    meta: profile6lYHa2IqkMMeta || {},
    component: () => import("/vercel/path1/pages/settings/profile.vue")
  },
  {
    name: "settings-security",
    path: "/settings/security",
    meta: securitygobTi49Ib1Meta || {},
    component: () => import("/vercel/path1/pages/settings/security.vue")
  }
]