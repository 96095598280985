<template>
    <div class="flex flex-col align-middle items-center justify-center h-full">
        <Steps
            :model="[
                {
                    label: 'Registrace',
                },
                {
                    label: 'Ověření emailu',
                },
                {
                    label: 'Vyvoření firemního profilu',
                },
                {
                    label: 'Výběr předplatného',
                },
                {
                    label: 'Hotovo',
                },
            ]"
            :activeStep="0"
            class="my-4 w-full mx-auto" />
        <h1 class="text-4xl text-gray-800 dark:text-white font-bold mt-6 mb-4">
            Registrace
        </h1>
        <div
            class="flex flex-col items-center align-middle w-full md:w-2/3 lg:w-1/2">
            <div class="w-full flex flex-col lg:flex-row">
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-2">
                    <label
                        for="firstName"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        Jméno
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        autocomplete="given-name"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="Jméno"
                        v-model="firstName" />
                </div>
                <div class="flex flex-col w-full lg:w-1/2 lg:ml-1 mt-2">
                    <label
                        for="lastName"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        Příjmení
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        autocomplete="family-name"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="Příjmení"
                        v-model="lastName" />
                </div>
            </div>
            <div class="flex flex-col w-full mt-2">
                <label
                    for="email"
                    class="text-gray-800 dark:text-white text-base font-bold">
                    Váš email
                </label>
                <input
                    type="text"
                    id="email"
                    autocomplete="email"
                    class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                    placeholder="Váš email"
                    v-model="email" />
            </div>
            <div class="flex flex-col w-full mt-2">
                <label
                    for="firstName"
                    class="text-gray-800 dark:text-white text-base font-bold">
                    Váš telefon
                </label>
                <input
                    type="text"
                    id="phone"
                    autocomplete="tel"
                    class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                    placeholder="Váš telefon"
                    v-model="phone" />
            </div>
            <div class="flex flex-col w-full mt-2">
                <label
                    for="password"
                    class="text-gray-800 dark:text-white text-base font-bold">
                    Heslo
                </label>
                <input
                    type="password"
                    id="password"
                    autocomplete="new-password"
                    class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                    placeholder="Heslo"
                    v-model="password" />
            </div>
            <div class="flex flex-col w-full mt-2">
                <label
                    for="passwordAgain"
                    class="text-gray-800 dark:text-white text-base font-bold">
                    Heslo znovu
                </label>
                <input
                    type="password"
                    id="passwordAgain"
                    autocomplete="new-password"
                    class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                    placeholder="Heslo znovu"
                    v-model="passwordAgain" />
            </div>
            <div class="flex flex-row items-center w-full mt-2">
                <Checkbox
                    inputId="terms"
                    class="mr-2"
                    :binary="true"
                    v-model="terms" />
                <label
                    for="terms"
                    class="text-gray-800 dark:text-white text-sm font-bold">
                    Souhlasím s podmínkami a ochranou osobních údajů služby
                    Notifya
                </label>
            </div>
            <div class="flex flex-row items-center w-full mt-2">
                <Checkbox
                    inputId="analytics"
                    class="mr-2"
                    :binary="true"
                    v-model="analytics" />
                <label
                    for="analytics"
                    class="text-gray-800 dark:text-white text-sm font-bold">
                    Souhlasím s anonymním sběrem dat pro zlepšení služby Notifya
                </label>
            </div>
            <ButtonsSubmit
                title="Pokračovat"
                class="w-full m-6"
                :small="false"
                :loading="isLoading"
                @click="() => signUp()" />
        </div>
    </div>
</template>
<script lang="ts">
import Steps from "primevue/steps";
import Checkbox from "primevue/checkbox";
definePageMeta({
    layout: "onboarding",
});

export default defineNuxtComponent({
    name: "Signup",
    setup() {
        useSeoMeta({
            title: "Registrace | Notifya",
            description: "Registrace do aplikace Notifya",
        });
        const firstName = ref("");
        const lastName = ref("");
        const email = ref("");
        const password = ref("");
        const passwordAgain = ref("");
        const phone = ref("+420");
        const terms = ref(false);
        const analytics = ref(false);

        const isLoading = ref(false);
        const userStore = useUser();
        const errorStore = useErrorMessagesStore();

        return {
            email,
            password,
            passwordAgain,
            firstName,
            lastName,
            phone,
            isLoading,
            userStore,
            terms,
            analytics,
            errorStore
        };
    },
    methods: {
        async signUp() {
            if (this.isLoading) return;
            // Trim all inputs
            this.firstName = this.firstName.trim();
            this.lastName = this.lastName.trim();
            this.phone = this.phone.trim();
            this.email = this.email.trim();
            // Check if all fields are filled
            if (
                !this.email ||
                !this.password ||
                !this.passwordAgain ||
                !this.firstName ||
                !this.lastName ||
                !this.phone
            ) {
                return this.$toast.warning("Všechny pole musí být vyplněny");
            }
            // Check if passwords match
            if (this.password !== this.passwordAgain) {
                return this.$toast.warning("Hesla se neshodují");
            }
            // Validation
            if (
                !this.firstName ||
                this.firstName.length < 2 ||
                this.firstName.length > 20 ||
                !this.firstName.match(/^[a-zA-ZÀ-ž]+$/)
            ) {
                return this.$toast.warning(
                    "Neplatné jméno, musí obsahovat pouze písmena a být dlouhé 2-20 znaků"
                );
            }
            if (
                !this.lastName ||
                this.lastName.length < 2 ||
                this.lastName.length > 25 ||
                !this.lastName.match(/^[a-zA-ZÀ-ž]+$/)
            ) {
                return this.$toast.warning(
                    "Neplatné příjmení, musí obsahovat pouze písmena a být dlouhé 2-25 znaků"
                );
            }
            if (
                !this.phone ||
                this.phone.length != 13 ||
                !this.phone.match(/^(\+420[0-9]{9})/)
            ) {
                return this.$toast.warning(
                    "Neplatné telefonní číslo, musí být ve formátu +420123456789"
                );
            }
            if (!this.email || !this.email.match(/^\S+@\S+\.\S+$/)) {
                return this.$toast.warning("Neplatný email");
            }
            if (!this.terms) {
                return this.$toast.warning(
                    "Musíte souhlasit s podmínkami a ochranou osobních údajů"
                );
            }
            this.isLoading = true;
            try {
                const resp: any = await this.$apiPostRequest("/auth/signup", {
                    email: this.email,
                    password: this.password,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    analyticsOptOut: !this.analytics,
                });
                if (resp.status === "success") {
                    this.$toast.success("Registrace proběhla úspěšně");
                    this.$router.push("/onboarding/verify-email");
                } else {
                    this.$toast.error(resp._data.message);
                }
            } catch (e: any) {
                const message = await this.errorStore.getMessageByCode(e!.response?._data.error);
                this.$toast.error(message ?? "Něco se pokazilo, zkuste to prosím znovu");
            } finally {
                this.isLoading = false;
            }
        },
    },
    components: {
        Steps,
        Checkbox,
    },
});
</script>
