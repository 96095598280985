<template>
    <div class="h-full w-full flex flex-col">
        <div class="flex flex-col">
            <div
                class="bg-gray-300 dark:bg-gray-800 rounded-lg px-5 py-3 flex flex-row items-center justify-between">
                <HomeSectionHeader>Platba a plán</HomeSectionHeader>
            </div>
        </div>
        <div
            v-if="loading"
            class="flex items-center w-full h-full align-middle justify-center">
            <Loading />
        </div>
        <div v-else-if="error" class="flex flex-col">
            <span class="text-gray-800 dark:text-white">
                {{ error }}
            </span>
        </div>
        <div v-else class="flex flex-col">
            <div v-if="!currentPlan">
                <h3 class="text-gray-600 dark:text-gray-400 text-2xl mt-5">
                    Aktuální plán
                </h3>
                <span
                    class="text-gray-800 dark:text-white text-lg mt-2 mb-5 font-bold">
                    Nemáte aktivní žádný plán předplatného, pro používání
                    služby, aktivujte prosím některý z plánů níže
                </span>
            </div>
            <h3
                class="text-gray-700 dark:text-gray-400 text-2xl mt-5"
                v-if="paymentMethods.length > 0 && !currentPlan">
                Platební metody
            </h3>
            <div
                class="flex flex-col my-4"
                v-if="paymentMethods.length > 0 && !currentPlan">
                <div
                    v-for="paymentMethod in paymentMethods"
                    class="px-3 py-2 bg-gray-700 text-white rounded-lg flex flex-row items-center justify-between w-80 my-1">
                    <div class="flex flex-row items-center">
                        <font-awesome-icon
                            v-if="paymentMethod.card.brand == 'visa'"
                            :icon="['fab', 'cc-visa']"
                            class="mr-2 text-2xl" />
                        <font-awesome-icon
                            v-if="paymentMethod.card.brand == 'mastercard'"
                            :icon="['fab', 'cc-mastercard']"
                            class="mr-2 text-2xl" />
                        <span class="capitalize"
                            >{{ paymentMethod.card.brand }} ••••
                            {{ paymentMethod.card.last4 }}</span
                        >
                        <span
                            v-if="paymentMethod.id == defaultPaymentMethod"
                            class="ml-3 text-green-500"
                            >Výchozí</span
                        >
                    </div>
                    <font-awesome-icon
                        @click="() => removePaymentMethod(paymentMethod.id)"
                        icon="trash"
                        class="text-red-500 hover:text-red-900 hover:cursor-pointer" />
                </div>
            </div>
            <!-- <div class="flex">
                <ButtonsSubmit
                    :small="true"
                    icon="plus"
                    title="Přidat platební metodu"
                    @click="() => (addPaymentMethodModal = true)" />
            </div> -->
            <template v-if="currentPlan">
                <h3 class="text-gray-600 dark:text-gray-400 text-2xl mt-5">
                    Aktuální předplatné
                </h3>
                <div
                    class="flex flex-col rounded-md bg-slate-200 dark:bg-gray-800 w-full md:w-fit p-5 mt-2 break-words">
                    <span
                        class="text-gray-700 dark:text-white"
                        v-if="!currentPlan.cancelAtPeriodEnd">
                        Aktuálně máte aktivní plán předplatného
                    </span>
                    <span class="text-red-500 dark:text-white" v-else>
                        Plán předplatného byl zrušen a bude aktivní do konce
                        aktuálního období.
                    </span>
                    <span class="text-gray-700 dark:text-white mt-2">
                        <b>ID předplatného:</b> {{ currentPlan.id }}
                    </span>
                    <span class="text-gray-700 dark:text-white mt-2">
                        <b>Cena:</b> {{ currentPlan.price / 100 }} Kč /
                        {{ interval }} + zaslané SMS připomínky
                    </span>
                    <span class="text-gray-700 dark:text-white mt-2">
                        <b>Aktuální období:</b>
                        {{
                            new Date(
                                currentPlan.currentPeriodStart
                            ).toLocaleDateString("cs-CZ")
                        }}
                        -
                        {{
                            new Date(
                                currentPlan.currentPeriodEnd
                            ).toLocaleDateString("cs-CZ")
                        }}
                    </span>
                    <span class="text-gray-700 dark:text-white mt-2">
                        <b>Platební metoda:</b>
                        {{
                            paymentMethods
                                .find(
                                    (pm) => pm.id == currentPlan?.paymentMethod
                                )
                                .card.brand.toUpperCase()
                        }}
                        ••••
                        {{
                            paymentMethods.find(
                                (pm) => pm.id == currentPlan?.paymentMethod
                            ).card.last4
                        }}
                    </span>
                    <span class="text-gray-700 dark:text-white mt-2">
                        <b>Stav:</b>
                        {{
                            currentPlan.cancelAtPeriodEnd
                                ? "Zrušeno, aktivní do konce období"
                                : currentPlan.status == "active"
                                ? "Aktivní"
                                : "Neaktivní"
                        }}
                    </span>
                    <ButtonsGeneric
                        icon="gears"
                        :border="true"
                        :small="true"
                        class="mt-4"
                        title="Spravovat předplatné"
                        @click="manageSubscription" />
                    <ButtonsGeneric
                        icon="times"
                        :border="true"
                        :small="true"
                        v-if="!currentPlan.cancelAtPeriodEnd"
                        class="border-red-500 mt-2"
                        title="Zrušit předplatné"
                        @click="cancelPlan" />
                    <ButtonsGeneric
                        icon="arrows-rotate"
                        :border="true"
                        :small="true"
                        v-if="currentPlan.cancelAtPeriodEnd"
                        class="mt-4"
                        title="Obnovit předplatné"
                        @click="renewPlan" />
                    <span
                        class="text-sm text-gray-500 w-full md:w-80 mt-2"
                        v-if="!currentPlan.cancelAtPeriodEnd">
                        <font-awesome-icon
                            icon="info-circle"
                            class="text-gray-500 mr-1" />
                        Plán předplatného lze zrušit kdykoliv. Po zrušení budete
                        mít přístup k službě až do konce aktuálního období.
                    </span>
                </div>
            </template>
            <template v-else>
                <h3 class="text-gray-400 text-2xl mt-5">Plány předplatného</h3>
                <div
                    v-for="plan in plans"
                    class="flex flex-row justify-between items-center my-5">
                    <PlanCard
                        :plan="plan"
                        @activate="() => activatePlan(plan.id)" />
                </div>
            </template>
            <h3 class="text-gray-600 dark:text-gray-400 text-2xl mt-5">
                Vydané faktury
            </h3>
            <div class="flex flex-col">
                <DataTable
                    :value="invoices"
                    :paginator="true"
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50]"
                    class="w-full mt-4">
                    <Column field="key" header="Číslo faktury" />
                    <Column field="getDate" header="Datum" />
                    <Column field="getTotal" header="Částka" />
                    <Column field="download" header="Stáhnout">
                        <template #body="slotProps">
                            <font-awesome-icon
                                icon="file-pdf"
                                class="text-gray-500 hover:text-gray-800 hover:cursor-pointer"
                                @click="
                                    () => downloadInvoice(slotProps.data.id)
                                " />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- <Modal
        title="Přidat platební metodu"
        :show="addPaymentMethodModal"
        @closeModal="() => (addPaymentMethodModal = false)">
        <div class="flex flex-col">
            <span class="text-lg mt-2 mb-5 block text-gray-400">
                Vyplňte prosím údaje o Vaší platební kartě
            </span>
            <div class="bg-white px-10 py-5 rounded-lg">
                <div id="cardNumber"></div>
            </div>
            <Loading v-if="loadingAddingPaymentMethod" class="mt-4 mx-auto" />
            <ButtonsGeneric
                v-else
                icon="check"
                :border="true"
                class="mt-5"
                title="Přidat platební metodu"
                @click="addPayment" />
        </div>
    </Modal> -->
</template>
<script lang="ts">
import { Invoice } from "~/models/invoice";
import type { Subscription } from "~/models/subscription";
import { type SubscriptionPlan } from "~~/models/plan";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

definePageMeta({
    layout: "settings",
});

export default defineNuxtComponent({
    setup() {
        useSeoMeta({
            title: "Platba a plán | Notifya",
            description: "Správa platebních metod a předplatného",
        });
        const loading = ref<boolean>(true);
        const error = ref<string | null>(null);
        const paymentMethods = ref<any[]>([]);
        const addPaymentMethodModal = ref(false);
        // const useStripe = useClientStripe();
        const elements = ref();
        const user = useUser();
        const loadingAddingPaymentMethod = ref(false);
        const defaultPaymentMethod = ref<string | null>(null);
        const plans = ref<SubscriptionPlan[]>([]);
        const currentPlan = ref<Subscription | null>(null);
        const businessesStore = useBusinessesStore();
        const invoices = ref<Invoice[]>([]);
        const router = useRouter();

        return {
            loading,
            error,
            paymentMethods,
            addPaymentMethodModal,
            // useStripe,
            elements,
            user,
            loadingAddingPaymentMethod,
            defaultPaymentMethod,
            plans,
            currentPlan,
            businessesStore,
            invoices,
            router,
        };
    },
    methods: {
        // async addPayment() {
        //     this.loadingAddingPaymentMethod = true;
        //     const cardElement = this.elements.getElement("card");
        //     const { token, error } = await this.useStripe.createToken(
        //         cardElement,
        //         {
        //             name: this.user.getName,
        //             address_line1: this.user.getStreet,
        //             address_city: this.user.getCity,
        //             address_zip: this.user.getZip,
        //             address_country: "CZ",
        //             currency: "czk",
        //         }
        //     );
        //     if (error) {
        //         this.$toast.error("Nepodařilo se přidat platební metodu");
        //         this.loadingAddingPaymentMethod = false;
        //         return;
        //     }
        //     const resp: any = await this.$apiPostRequest(
        //         "/billing/addPaymentMethod",
        //         {
        //             token: token.id,
        //         }
        //     ).catch((e: any) => {
        //         console.log(e);
        //         this.$toast.error("Nepodařilo se přidat platební metodu");
        //     });
        //     if (resp.status == "success") {
        //         this.$toast.success("Platební metoda byla úspěšně přidána");
        //         cardElement.clear();
        //         this.fetchData();
        //         this.addPaymentMethodModal = false;
        //     }
        //     this.loadingAddingPaymentMethod = false;
        // },
        async fetchData() {
            this.loading = true;
            await this.getPaymentMethods();
            await this.fetchPlans();
            await this.getCurrentPlan();
            await this.fetchInvoices();
            this.loading = false;
        },
        async getPaymentMethods() {
            const response = await this.$apiGetRequest(
                "/billing/getPaymentMethods?businessId=" +
                    this.businessesStore.getCurrentBusinessId
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se načíst platební metodu");
                this.loading = false;
                this.error = "Nepodařilo se načíst platební metody";
            });
            if (response.status == "success") {
                this.paymentMethods = response.data.paymentMethods;
                this.defaultPaymentMethod = response.data.defaultPaymentMethod;
                this.paymentMethods.sort((a, b) => {
                    if (a.id == this.defaultPaymentMethod) {
                        return -1;
                    }
                    if (b.id == this.defaultPaymentMethod) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
        async fetchPlans() {
            const response = await this.$apiGetRequest("/system/plans").catch(
                (e: any) => {
                    console.log(e);
                    this.$toast.error(
                        "Nepodařilo se načíst plány předplatného"
                    );
                    this.loading = false;
                    this.error = "Nepodařilo se načíst plány předplatného";
                }
            );
            if (response.status == "success") {
                this.plans = response.data.plans;
            }
        },
        async getCurrentPlan() {
            const response = await this.$apiGetRequest(
                "/billing/getCurrentPlan?businessId=" +
                    this.businessesStore.getCurrentBusinessId
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se načíst aktuální předplatné");
                this.loading = false;
                this.error = "Nepodařilo se načíst aktuální předplatné";
                return;
            });
            if (response.status == "success") {
                console.log(response.data.subscription);
                this.currentPlan = response.data.subscription;
            }
        },
        // createElement() {
        //     var cardElement = this.elements.create("card", {
        //         style: {
        //             base: {
        //                 fontSize: "15px",
        //             },
        //         },
        //     });
        //     cardElement.mount("#cardNumber");
        // },
        async removePaymentMethod(paymentMethodId: string) {
            if (this.currentPlan) {
                this.$toast.error(
                    "Nelze odebrat platební metodu, dokud máte aktivní předplatné"
                );
                return;
            }
            this.loading = true;
            const resp: any = await this.$apiDeleteRequest(
                "/billing/deletePaymentMethod/" +
                    paymentMethodId +
                    "?businessId=" +
                    this.businessesStore.getCurrentBusinessId
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se odebrat platební metodu");
            });
            if (resp.status == "success") {
                this.$toast.success("Platební metoda byla úspěšně odebrána");
                this.fetchData();
            }
            this.loading = false;
        },
        async activatePlan(id: string) {
            this.loading = true;
            const resp: any = await this.$apiPostRequest(
                "/billing/startSubscription",
                {
                    planId: id,
                    businessId: this.businessesStore.getCurrentBusinessId,
                }
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se aktivovat plán předplatného");
                return;
            });
            if (resp.status == "success") {
                navigateTo(resp.data.payment_link, {
                    external: true,
                });
            }
            // this.loading = false;
        },
        async cancelPlan() {
            this.loading = true;
            const resp: any = await this.$apiDeleteRequest(
                "/billing/cancelSubscription?businessId=" +
                    this.businessesStore.getCurrentBusinessId
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se zrušit plán předplatného");
                return;
            });
            if (resp.status == "success") {
                this.$toast.success("Plán předplatného byl úspěšně zrušen");
            }
            this.loading = false;
            this.fetchData();
        },
        async renewPlan() {
            this.loading = true;
            const resp: any = await this.$apiPostRequest(
                "/billing/renewSubscription?businessId=" +
                    this.businessesStore.getCurrentBusinessId,
                { paymentMethod: this.defaultPaymentMethod }
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se obnovit plán předplatného");
                return;
            });
            if (resp.status == "success") {
                this.$toast.success("Plán předplatného byl úspěšně obnoven");
            }
            this.loading = false;
            this.fetchData();
        },
        async fetchInvoices() {
            const response = await this.$apiGetRequest(
                "/billing/getInvoices?businessId=" +
                    this.businessesStore.getCurrentBusinessId
            ).catch((e: any) => {
                console.log(e);
                this.$toast.error("Nepodařilo se načíst faktury");
                return;
            });
            if (response.status == "success") {
                this.invoices = response.data.invoices.map(
                    (i: any) => new Invoice(i)
                );
            }
        },
        async downloadInvoice(invoiceId: string) {
            this.loading = true;
            try {
                const response = await this.$apiGetRequest(
                    "/billing/getInvoiceLink?invoiceId=" + invoiceId
                );
                if (response.status == "success") {
                    const link = response.data.invoiceLink;
                    this.router.resolve(link).href;
                    window.open(link, "_blank");
                } else {
                    this.$toast.error("Nepodařilo se stáhnout fakturu");
                }
            } catch (e) {
                console.log(e);
                this.$toast.error("Nepodařilo se stáhnout fakturu");
            } finally {
                this.loading = false;
            }
        },
        async manageSubscription() {
            try {
                this.loading = true;
                const response = await this.$apiGetRequest(
                    "/billing/getSubscriptionPortalLink?businessId=" +
                        this.businessesStore.getCurrentBusinessId
                );
                if (response.status == "success") {
                    navigateTo(response.data.url, {
                        external: true,
                    });
                } else {
                    this.$toast.error(
                        "Nepodařilo se otevřít správu předplatného"
                    );
                }
                this.loading = false;
            } catch (e) {
                console.log(e);
                this.$toast.error("Nepodařilo se otevřít správu předplatného");
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchData();
        // this.elements = this.useStripe.elements({
        //     locale: "cs",
        // });
        // this.createElement();
    },
    computed: {
        interval() {
            if (this.currentPlan) {
                let interval = "";
                switch (this.currentPlan.interval) {
                    case "month":
                        interval = "měsíc";
                        break;
                    case "year":
                        interval = "rok";
                        break;
                    case "quarter":
                        interval = "čtvrtletí";
                        break;
                }
                return interval;
            }
            return "";
        },
    },
    components: {
        DataTable,
        Column,
    },
});
</script>
