<template>
    <div
        class="flex flex-col items-center align-middle justify-center bg-white dark:bg-gray-900 w-screen h-screen">
        <NotifyaLogo classes="text-gray-800 dark:text-white text-6xl mb-5" />
        <Loading />
    </div>
</template>

<script lang="ts">
import Loading from "~/components/Loading.vue";
import NotifyaLogo from "~/components/NotifyaLogo.vue";
import { useBusinessesStore } from "~~/composables/businesss";
import { useUser } from "~~/composables/user";

definePageMeta({
    layout: "empty",
});

export default defineNuxtComponent({
    components: { Loading, NotifyaLogo },
    setup() {
        useSeoMeta({
            title: "Notifya - Aplikace",
            description: "Přihlašování do aplikace...",
        });
        const business = useBusinessesStore();
        const errorMessagesStore = useErrorMessagesStore();
        const user = useUser();
        const route = useRoute();

        return { business, errorMessagesStore, user, route };
    },
    async mounted() {
        try {
            const resp = await this.$apiGetRequest("/user");
            if (resp.status === "success") {
                this.$router.push("/dash");
            } else {
                this.$router.push("/auth/login");
            }
        } catch (e) {
            this.$router.push("/auth/login");
        }
    },
});
</script>
