<template>
    <div class="flex flex-col justify-center align-middle items-center h-full">
        <Steps
            :model="[
                {
                    label: 'Registrace',
                },
                {
                    label: 'Ověření emailu',
                },
                {
                    label: 'Vyvoření firemního profilu',
                },
                {
                    label: 'Výběr předplatného',
                },
                {
                    label: 'Hotovo',
                },
            ]"
            :activeStep="2"
            class="my-4" />
        <h1 class="text-4xl text-gray-800 dark:text-white font-bold mt-6 mb-4 text-center">
            Vytvoření firemního profilu
        </h1>
        <span
            class="text-gray-600 dark:text-white text-base text-center mb-4 w-2/3">
            Vytvořte si firemní profil, pod kterým budete mít uloženy všechny
            vaše objednávky a zákazníky. Název tohoto profilu také uvidí
            zákazníci v emailech, SMSkách a podobně...
        </span>
        <div class="flex flex-col items-center align-middle w-full md:w-2/3">
            <div class="flex flex-col w-full mt-3">
                <label
                    for="name"
                    class="text-gray-800 dark:text-white text-base font-bold">
                    Jméno firemního profilu (uvidí zákazníci)
                </label>
                <input
                    type="text"
                    id="name"
                    autocomplete="organization-title"
                    class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                    placeholder="Jméno firemního profilu"
                    v-model="name" />
            </div>
            <div class="w-full flex flex-col lg:flex-row">
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-3">
                    <label
                        for="phone"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        Telefon
                    </label>
                    <input
                        type="text"
                        id="phone"
                        autocomplete="tel"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="Telefon"
                        v-model="phone" />
                </div>
                <div class="flex flex-col w-full lg:w-1/2 lg:ml-1 mt-3">
                    <label
                        for="email"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        Email
                    </label>
                    <input
                        type="text"
                        id="email"
                        autocomplete="email"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="Email"
                        v-model="email" />
                </div>
            </div>
            <h3 class="text-xl text-gray-800 dark:text-white font-bold mt-6">
                Fakturační údaje
            </h3>
            <div class="w-full flex flex-col lg:flex-row">
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-3">
                    <label
                        for="companyId"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        IČO
                    </label>
                    <input
                        type="text"
                        id="companyId"
                        autocomplete="organization-id"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="IČO"
                        v-model="companyId" />
                </div>
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-3">
                    <label
                        for="companyId"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        DIČ (nepovinné)
                    </label>
                    <input
                        type="text"
                        id="vatId"
                        autocomplete="vat-id"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="DIČ"
                        v-model="vatId" />
                </div>
            </div>
            <div class="flex flex-col w-full mt-3">
                <label
                    for="businessName"
                    class="text-gray-800 dark:text-white text-base font-bold">
                    Obchodní název (bude na fakturách)
                </label>
                <input
                    type="text"
                    id="businessName"
                    autocomplete="organization-title"
                    class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                    placeholder="Obchodní název"
                    v-model="companyName" />
            </div>
            <div class="w-full flex flex-col lg:flex-row">
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-3">
                    <label
                        for="street"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        Ulice
                    </label>
                    <input
                        type="text"
                        id="street"
                        autocomplete="street-address"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="Ulice"
                        v-model="street" />
                </div>
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-3">
                    <label
                        for="city"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        Město
                    </label>
                    <input
                        type="text"
                        id="city"
                        autocomplete="address-level2"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="Město"
                        v-model="city" />
                </div>
            </div>
            <div class="w-full flex flex-col lg:flex-row">
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-3">
                    <label
                        for="zip"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        PSČ
                    </label>
                    <input
                        type="text"
                        id="zip"
                        autocomplete="postal-code"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="PSČ"
                        v-model="zip" />
                </div>
                <div class="flex flex-col w-full lg:w-1/2 lg:mr-1 mt-3">
                    <label
                        for="country"
                        class="text-gray-800 dark:text-white text-base font-bold">
                        Země
                    </label>
                    <input
                        type="text"
                        id="country"
                        autocomplete="country-name"
                        class="mt-1 py-2 text-gray-900 dark:text-white text-base form-cotrol w-full px-4 bg-clip-padding rounded-md transition ease-in bg-gray-300 dark:bg-gray-800 focus:outline-none"
                        placeholder="Země"
                        v-model="country" />
                </div>
            </div>
            <ButtonsSubmit
                title="Pokračovat"
                class="w-full m-6"
                :small="false"
                :loading="isLoading"
                @click="() => create()" />
        </div>
    </div>
</template>
<script lang="ts">
import { getAresDataByIco } from "~/helpers/ares";
import { isCompanyIdValid } from "~/helpers/companyIdValidation";
definePageMeta({
    layout: "onboarding",
});
import Steps from "primevue/steps";
export default defineNuxtComponent({
    name: "Signup",
    setup() {
        useSeoMeta({
            title: "Nový firemní profil | Notifya",
            description: "Registrace firemního profilu do aplikace Notifya",
        });
        const name = ref("");
        const email = ref("");
        const phone = ref("+420");
        const companyId = ref("");
        const vatId = ref("");

        const companyName = ref("");
        const street = ref("");
        const city = ref("");
        const zip = ref("");
        const country = ref("");

        const isLoading = ref(false);
        const userStore = useUser();
        const businesses = useBusinessesStore();

        return {
            email,
            name,
            companyName,
            companyId,
            vatId,
            street,
            city,
            zip,
            country,
            phone,
            isLoading,
            userStore,
            getAresDataByIco,
            businesses,
        };
    },
    components: {
        Steps,
    },
    methods: {
        async create() {
            if (this.isLoading) return;
            // Trim all fields
            this.name = this.name.trim();
            this.email = this.email.trim();
            this.phone = this.phone.trim();
            this.companyId = this.companyId.toString().trim();
            this.companyName = this.companyName.trim();
            this.street = this.street.trim();
            this.city = this.city.trim();
            this.zip = this.zip.toString().trim();
            this.country = this.country.trim();
            // Check if all fields are filled
            if (
                !this.name ||
                !this.email ||
                !this.phone ||
                !this.companyId ||
                !this.companyName ||
                !this.street ||
                !this.city ||
                !this.zip ||
                !this.country
            ) {
                this.$toast.warning("Všechny povinné pole musí být vyplněny");
                return;
            }
            // Check if company ID is valid
            if (!isCompanyIdValid(this.companyId)) {
                this.$toast.warning("IČO není validní");
                return;
            }
            // Check name
            if (!this.name || this.name.length < 3 || this.name.length > 32) {
                this.$toast.warning(
                    "Jméno firemního profilu musí mít alespoň 3 znaky"
                );
                return;
            }
            // Check phone
            if (
                !this.phone ||
                this.phone.length != 13 ||
                !this.phone.match(/^(\+420[0-9]{9})/)
            ) {
                return this.$toast.warning(
                    "Neplatné telefonní číslo, musí být ve formátu +420123456789"
                );
            }
            // Check email
            if (!this.email || !this.email.match(/^\S+@\S+\.\S+$/)) {
                return this.$toast.warning("Neplatný email");
            }
            this.isLoading = true;
            try {
                const response: any = await this.$apiPostRequest(
                    "/business/create",
                    {
                        name: this.name,
                        address: {
                            street: this.street,
                            city: this.city,
                            zipCode: this.zip,
                            country: this.country,
                            name: this.companyName,
                        },
                        phone: this.phone,
                        email: this.email,
                        companyId: this.companyId,
                        vatId: this.vatId || null,
                    }
                );
                if (response.status === "success") {
                    this.$toast.success("Firemní profil byl úspěšně vytvořen");
                    await this.businesses.loadBusinesses();
                    this.$router.push("/onboarding/plan");
                } else {
                    this.$toast.error("Nepodařilo se vytvořit firemní profil");
                    this.isLoading = false;
                }
            } catch (e) {
                this.$toast.error("Nepodařilo se vytvořit firemní profil");
                this.isLoading = false;
            }
        },
    },
    mounted() {
        watch(
            () => this.companyId,
            async (newValue, _) => {
                if (newValue.trim().length == 8 && isCompanyIdValid(newValue)) {
                    const aresData = await getAresDataByIco(newValue);
                    if (!aresData) {
                        this.$toast.warning(
                            "Nepodařilo se načíst data z ARESu"
                        );
                        return;
                    }
                    this.companyName = aresData.name;
                    this.street = aresData.street;
                    this.city = aresData.city;
                    this.zip = aresData.postalCode.toString();
                    this.country = aresData.country;
                    if (aresData.dic) this.vatId = aresData.dic;
                }
            }
        );
    },
});
</script>
