<template>
    <div class="h-full w-full flex flex-col">
        <div
            class="bg-gray-300 dark:bg-gray-800 rounded-lg px-5 py-3 flex flex-row items-center justify-between">
            <HomeSectionHeader>Online rezervace</HomeSectionHeader>
            <div class="flex flex-row">
                <ButtonsGeneric
                    icon="power-off"
                    title="Deaktivovat modul"
                    class="border-red-500 hover:bg-red-500 hover:text-white"
                    :border="true"
                    @click="() => disable()" />
                <ButtonsSubmit
                    title="Uložit"
                    @click="() => save()"
                    class="ml-4"
                    icon="check" />
            </div>
        </div>
        <span
            v-if="
                businessesStore.getCurrentBusiness?.modules.reservations ==
                false
            "
            class="text-gray-800 dark:text-white mt-2">
            Modul není aktivní
        </span>
        <div
            v-if="loading"
            class="flex items-center w-full h-full align-middle justify-center">
            <Loading />
        </div>
        <div class="flex flex-col mt-2" v-else>
            <span class="text-gray-600 dark:text-gray-400 text-lg"
                >Status: Aktivní</span
            >
            <h3
                class="mt-2 text-2xl text-gray-800 dark:text-gray-200 font-bold">
                Nastavení domény
            </h3>
            <span class="text-gray-800 dark:text-gray-200 mt-2">
                Nastavte si doménu, na které bude online rezervace pro zákazníky
                dostupná. Vaše doména bude ve tvaru
                <b>vasedomena.rezervace.notifya.cz</b>
            </span>
            <div class="flex flex-row align-middle items-center mt-1">
                <input
                    type="text"
                    v-model="domain"
                    class="w-72 dark:border rounded-lg p-2 bg-gray-300 dark:bg-gray-800 border-gray-600 text-gray-800 dark:text-white mt-1"
                    placeholder="vasedomena" />
                <ButtonsSubmit
                    title="Použít doménu"
                    @click="useDomain"
                    :disabled="
                        !domain ||
                        domain.length === 0 ||
                        domainAvailable === false ||
                        domainChecking ||
                        currentDomain === domain
                    "
                    class="ml-4"
                    icon="check" />
            </div>
            <span v-if="domain.length < 4" class="mt-2"
                >Napište doménu v délce 4 až 15 znaků pro zkontrolování
                dostupnosti</span
            >
            <span v-else-if="domainChecking" class="mt-2"
                >Kontrolování dostupnosti...</span
            >
            <span
                v-else-if="domainAvailable"
                class="text-green-500 dark:text-green-300 mt-2"
                >Doména je dostupná</span
            >
            <span
                v-else-if="!domainAvailable && domain !== currentDomain"
                class="text-red-500 dark:text-red-300 mt-2"
                >Doména není dostupná, zkuste prosím jinou</span
            >
            <span
                v-if="currentLink"
                class="mt-2 text-gray-800 dark:text-gray-200">
                Aktuální odkaz na Váš rezervační systém je:
                <NuxtLink
                    target="_blank"
                    class="text-emerald-600 font-bold"
                    :to="currentLink"
                    >{{ currentLink }}</NuxtLink
                >
            </span>
            <h3
                class="mt-2 text-2xl text-gray-800 dark:text-gray-200 font-bold">
                Kdy máte otevřeno:
            </h3>
            <div
                class="flex flex-row align-middle items-center mt-1"
                v-for="(_, i) in openingHours">
                <Checkbox
                    class="mr-2"
                    :binary="true"
                    v-model="openingHours[i].open" />
                <span class="text-gray-800 dark:text-gray-200 font-bold w-24"
                    >{{ getDayByIndex(i) }}:</span
                >
                <span
                    v-if="!openingHours[i].open"
                    class="text-gray-800 dark:text-gray-200 font-bold p-2"
                    >Zavřeno</span
                >
                <div class="flex flex-row items-center" v-else>
                    <!-- OPEN -->
                    <span class="text-gray-800 dark:text-gray-200 mr-2"
                        >Od:</span
                    >
                    <select
                        v-model="openingHours[i].openAt"
                        class="w-20 dark:border rounded-lg p-2 bg-gray-300 dark:bg-gray-800 border-gray-600 text-gray-800 dark:text-white mr-2">
                        <option
                            v-for="index in getTimeIndex(
                                openingHours[i].closeAt
                            ) - 1"
                            :value="getTimeFromIndex(index)">
                            {{ getTimeFromIndex(index) }}
                        </option>
                    </select>
                    <!-- CLOSE -->
                    <span class="text-gray-800 dark:text-gray-200 mr-2"
                        >Do:</span
                    >
                    <select
                        v-model="openingHours[i].closeAt"
                        class="w-20 dark:border rounded-lg p-2 bg-gray-300 dark:bg-gray-800 border-gray-600 text-gray-800 dark:text-white mr-2">
                        <option
                            v-for="index in 47 -
                            getTimeIndex(openingHours[i].openAt)"
                            :value="
                                getTimeFromIndex(
                                    index + getTimeIndex(openingHours[i].openAt)
                                )
                            ">
                            {{
                                getTimeFromIndex(
                                    index + getTimeIndex(openingHours[i].openAt)
                                )
                            }}
                        </option>
                    </select>
                    <!-- BREAK -->
                    <span class="text-gray-800 dark:text-gray-200 mr-2"
                        >Přestávka:</span
                    >
                    <select
                        v-model="openingHours[i].breakFrom"
                        @change="
                            () => setBrake('from', i, openingHours[i].breakFrom)
                        "
                        class="w-20 dark:border rounded-lg p-2 bg-gray-300 dark:bg-gray-800 border-gray-600 text-gray-800 dark:text-white mr-2">
                        <option :value="null">Žádná</option>
                        <option
                            v-for="index in getBrakePartsAndIndex(i).parts"
                            :value="
                                getTimeFromIndex(
                                    index + getBrakePartsAndIndex(i).padding
                                )
                            ">
                            {{
                                getTimeFromIndex(
                                    index + getBrakePartsAndIndex(i).padding
                                )
                            }}
                        </option>
                    </select>
                    <span class="text-gray-800 dark:text-gray-200 mr-2">-</span>
                    <select
                        @change="
                            () => setBrake('to', i, openingHours[i].breakTo)
                        "
                        v-model="openingHours[i].breakTo"
                        class="w-20 dark:border rounded-lg p-2 bg-gray-300 dark:bg-gray-800 border-gray-600 text-gray-800 dark:text-white mr-2">
                        <option :value="null">Žádná</option>
                        <option
                            v-for="index in getBrakePartsAndIndex(i).parts"
                            :value="
                                getTimeFromIndex(
                                    index + getBrakePartsAndIndex(i).padding
                                )
                            ">
                            {{
                                getTimeFromIndex(
                                    index + getBrakePartsAndIndex(i).padding
                                )
                            }}
                        </option>
                    </select>
                </div>
            </div>
            <h3
                class="mt-3 text-2xl text-gray-800 dark:text-gray-200 font-bold">
                Ostatní nastavení:
            </h3>
            <div class="flex flex-row align-middle items-center mt-1">
                <Checkbox
                    v-model="requireSmsVerification"
                    inputId="requireSmsVerification"
                    class="mr-2"
                    :binary="true" />
                <label
                    class="text-gray-800 dark:text-gray-200 font-bold"
                    for="requireSmsVerification"
                    >Vyžadovat ověření přes SMS při vytváření nové
                    rezervace</label
                >
            </div>
            <div class="flex flex-row align-middle items-center mt-1">
                <Checkbox
                    v-model="showServices"
                    inputId="showServices"
                    class="mr-2"
                    :binary="true" />
                <label
                    class="text-gray-800 dark:text-gray-200 font-bold"
                    for="showServices">
                    Zobrazovat dostupné služby v rezervačním formuláři
                </label>
            </div>
            <div class="flex flex-row align-middle items-center mt-1">
                <Checkbox
                    v-model="requireApproval"
                    inputId="requireApproval"
                    class="mr-2"
                    :binary="true" />
                <label
                    class="text-gray-800 dark:text-gray-200 font-bold"
                    for="requireApproval"
                    >Vyžadovat schválení rezervace
                </label>
            </div>
            <div class="flex flex-row align-middle items-center mt-1">
                <Checkbox
                    v-model="showPrices"
                    inputId="showPrices"
                    class="mr-2"
                    :binary="true" />
                <label
                    class="text-gray-800 dark:text-gray-200 font-bold"
                    for="showPrices"
                    >Zobrazovat ceny v rezervačním formuláři
                </label>
            </div>
            <div class="flex flex-row align-middle items-center mt-1">
                <Checkbox
                    v-model="multipleServices"
                    inputId="multipleServices"
                    class="mr-2"
                    :binary="true" />
                <label
                    class="text-gray-800 dark:text-gray-200 font-bold"
                    for="multipleServices"
                    >Povolit rezervaci více služeb najednou
                </label>
            </div>
            <span class="text-gray-800 dark:text-gray-200 font-bold mt-2"
                >Zákazníci se můžou objednat nejpozděj:</span
            >
            <select
                v-model="lastReservationTime"
                class="w-72 dark:border rounded-lg p-2 bg-gray-300 dark:bg-gray-800 border-gray-600 text-gray-800 dark:text-white mt-1">
                <option v-for="i in lastReservationTimes" :value="i.value">
                    {{ i.text }} předem
                </option>
            </select>
            <span class="text-gray-800 dark:text-gray-200 font-bold mt-2"
                >Zákazníci se můžou objednat nejdříve:</span
            >
            <select
                v-model="firstReservationTime"
                class="w-72 dark:border rounded-lg p-2 bg-gray-300 dark:bg-gray-800 border-gray-600 text-gray-800 dark:text-white mt-1">
                <option v-for="i in firstReservationTimes" :value="i.value">
                    {{ i.text }} předem
                </option>
            </select>
        </div>
    </div>
</template>
<script lang="ts">
import type { OpeningHours } from "~/models/openingHours";
import {
    lastReservationTimes,
    firstReservationTimes,
} from "./reservationTimes";
import { getTimeIndex, getTimeFromIndex } from "~/helpers/timeHelper";
import Checkbox from "primevue/checkbox";
import Panel from 'primevue/panel';
definePageMeta({
    layout: "settings",
});
export default defineNuxtComponent({
    setup() {
        useSeoMeta({
            title: "Nastavení online rezervací | Notifya",
            description: "Nastavení online rezervací",
        });
        const businessesStore = useBusinessesStore();
        const loading = ref(true);
        const openingHours = ref<OpeningHours[]>(
            Array.from({ length: 8 }, (_, i) => ({
                open: false,
                openAt: "07:00",
                closeAt: "20:00",
                breakFrom: null,
                breakTo: null,
            }))
        );
        const requireSmsVerification = ref(false);
        const showServices = ref(true);
        const showProducts = ref(false);
        const requireApproval = ref(false);
        const showPrices = ref(false);
        const firstReservationTime = ref(720);
        const lastReservationTime = ref(24);
        const multipleServices = ref(false);
        const domain = ref("");
        const domainAvailable = ref(false);
        const domainChecking = ref(false);
        const currentDomain = ref("");
        const currentLink = ref("");

        return {
            businessesStore,
            loading,
            openingHours,
            requireSmsVerification,
            showServices,
            showProducts,
            requireApproval,
            showPrices,
            firstReservationTime,
            lastReservationTime,
            firstReservationTimes,
            lastReservationTimes,
            multipleServices,
            getTimeFromIndex,
            getTimeIndex,
            domain,
            domainAvailable,
            domainChecking,
            currentDomain,
            currentLink,
        };
    },
    methods: {
        async save() {
            for (let i of this.openingHours) {
                if (!i.open) {
                    i = {
                        open: false,
                        openAt: "07:00",
                        closeAt: "20:00",
                        breakFrom: null,
                        breakTo: null,
                    };
                }
                const openIndex = getTimeIndex(i.openAt);
                const closeIndex = getTimeIndex(i.closeAt);
                if (openIndex >= closeIndex) {
                    this.$toast.error("Chybně zadaná otevírací doba");
                    return;
                }
                if (i.breakFrom == null || i.breakTo == null) continue;
                const breakFromIndex = getTimeIndex(i.breakFrom!);
                const breakToIndex = getTimeIndex(i.breakTo!);
                if (
                    breakFromIndex >= breakToIndex ||
                    breakFromIndex < openIndex ||
                    breakToIndex > closeIndex
                ) {
                    this.$toast.error("Chybně zadaná přestávka");
                    return;
                }
            }
            this.loading = true;
            const resp: any = await this.$apiPostRequest(
                "/business/modules/reservations",
                {
                    businessId: this.businessesStore.getCurrentBusinessId,
                    openingHours: this.openingHours,
                    requireSmsVerification: this.requireSmsVerification,
                    showServices: this.showServices,
                    showProducts: this.showProducts,
                    requireApproval: this.requireApproval,
                    showPrices: this.showPrices,
                    firstReservationTime: this.firstReservationTime,
                    lastReservationTime: this.lastReservationTime,
                    multipleServices: this.multipleServices,
                }
            ).catch((err: any) => {
                this.loading = false;
                this.$toast.error("Nastala chyba při ukládání dat");
            });
            if (resp.status == "success") {
                this.$toast.success("Nastavení bylo uloženo");
            } else {
                this.$toast.error("Nastala chyba při ukládání dat");
            }
            this.loading = false;
        },
        async fetchData() {
            this.loading = true;
            const resp: any = await this.$apiGetRequest(
                "/business/modules/getConfig/?module=reservations&businessId=" +
                    this.businessesStore.getCurrentBusinessId
            ).catch((err: any) => {
                this.loading = false;
                this.$toast.error("Nastala chyba při načítání dat");
            });
            if (resp.status == "success") {
                this.openingHours = resp.data.config.openingHours;
                this.requireSmsVerification =
                    resp.data.config.requireSmsVerification;
                this.showServices = resp.data.config.showServices;
                this.showProducts = resp.data.config.showProducts;
                this.requireApproval = resp.data.config.requireApproval;
                this.showPrices = resp.data.config.showPrices;
                this.firstReservationTime =
                    resp.data.config.firstReservationTime;
                this.lastReservationTime = resp.data.config.lastReservationTime;
                this.multipleServices = resp.data.config.multipleServices;
                this.currentDomain = resp.data.domain || "";
                this.domain = resp.data.domain || "";
                this.currentLink = resp.data.link;
            } else {
                this.$toast.error("Nastala chyba při načítání dat");
            }
            this.loading = false;
        },
        async disable() {
            this.loading = true;
            let response: any = await this.$apiPostRequest(
                `/business/modules`,
                {
                    module: "reservations",
                    value: false,
                    id: this.businessesStore.getCurrentBusinessId,
                }
            ).catch((e: any) => {
                console.error(e);
                this.$toast.error("Nepodařilo se deaktivovat modul.");
            });
            if (response.status !== "success") {
                console.error(response);
                this.$toast.error(response.message);
            } else {
                this.$toast.success("Modul byl deaktivován.");
                var b = this.businessesStore.getCurrentBusiness;
                b!.modules = response.data.modules;
                this.businessesStore.updateCurrentBusiness(b);
                this.$router.push("/settings/modules");
            }
        },
        getDayByIndex(index: number) {
            const days = [
                "Pondělí",
                "Úterý",
                "Středa",
                "Čtvrtek",
                "Pátek",
                "Sobota",
                "Neděle",
                "Svátek",
            ];
            return days[index];
        },
        setOpening(type: "from" | "to", index: number, value: string) {
            if (type == "from") {
                this.openingHours[index].openAt = value;
            } else {
                this.openingHours[index].closeAt = value;
            }
        },
        setBrake(type: "from" | "to", index: number, value: string | null) {
            if (value == null) {
                this.openingHours[index].breakFrom = null;
                this.openingHours[index].breakTo = null;
                return;
            }
            if (type == "from") {
                this.openingHours[index].breakFrom = value;
            } else {
                this.openingHours[index].breakTo = value;
            }
        },
        getBrakePartsAndIndex(index: number) {
            var openAtHours = getTimeIndex(this.openingHours[index].openAt);
            var closeAtHours = getTimeIndex(this.openingHours[index].closeAt);
            // Get number of 30 minutes parts from openAt to closeAt
            const parts = closeAtHours - openAtHours;
            const padding = openAtHours;
            return { parts, padding };
        },
        async checkDomainAvailability() {
            this.domainChecking = true;
            try {
                const resp: any = await this.$apiGetRequest(
                    `/business/domains/check?subdomain=${this.domain}`
                );
                console.log(resp);
                this.domainAvailable = resp.data.available;
                this.domainChecking = false;
            } catch (e) {
                console.error(e);
                this.$toast.error(
                    "Nepodařilo se zkontrolovat dostupnost domény"
                );
                this.domainChecking = false;
                this.domainAvailable = false;
                this.domain = this.currentDomain;
            }
        },
        async useDomain() {
            if (this.domain == this.currentDomain) {
                this.$toast.error("Tato doména je již nastavena");
                this.loading = false;
                return;
            }
            if (
                this.domain.length < 4 ||
                this.domain.length > 15 ||
                !this.domainAvailable
            )
            return;
            
            this.loading = true;
            try {
                const resp: any = await this.$apiPostRequest(
                    `/business/domains/add`,
                    {
                        subdomain: this.domain.trim(),
                        businessId: this.businessesStore.getCurrentBusinessId,
                    }
                );
                if (resp.status == "success") {
                    this.$toast.success("Doména byla nastavena");
                    this.currentDomain = this.domain;
                    this.currentLink = resp.data.link;
                    this.domainAvailable = false;
                } else {
                    this.$toast.error("Nepodařilo se nastavit doménu");
                }
            } catch (e) {
                console.error(e);
                this.$toast.error("Nepodařilo se nastavit doménu");
            }
            this.loading = false;
        },
    },
    mounted() {
        this.fetchData();
        watch(
            () => this.domain,
            () => {
                if (
                    this.domain &&
                    this.domain.length > 3 &&
                    this.domain.length < 16 &&
                    this.domain !== this.currentDomain
                )
                    this.checkDomainAvailability();
            }
        );
    },
    components: {
        Checkbox,
    },
});
</script>
